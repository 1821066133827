const contextTap = async (cy: any) => {
  return await cy.on('cxttap', 'node', async function (e) {
    const element = e.target;

    element.addClass('selected');
    
    cy.elements()
      .difference(element)
      .removeClass('selected');
  });
};

export default contextTap;
