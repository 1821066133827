import React, { useRef, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Table, Resize, MarkdownEditor, MarkdownFormatter, PasteCleanup, PasteCleanupSettingsModel } from '@syncfusion/ej2-react-richtexteditor';
import { useTranslation } from 'react-i18next';
import { setTextSelection } from '../../../../store/workspaces/modals/actions';
import { getToolbarSetting } from './toolbar/toolbar.settings';
import PropTypes from 'prop-types';
import { FormInputContainerStyled, FormInputLabelContainerStyled, ValidationLabelStyled } from '../styles';
import { setDisableLinkClass } from './services/disableLinks';
import { setPreview } from './services/setPreview';
import { fullPreviewMarkdown } from './services/fullPreviewMarkdown';
import { convertMarkdown, markdownConversion } from './services/markdownConversion';
import store from '../../../../store/store';
import { setNavigationOpen } from '../../../../store/navigation/actions';
import { setInlineMenuEvents } from './services/setInlineMenuEvents';

const RichTextField = ({ viewType, field, value, params, renderData, setNewValue }) => {
  const instanceEdit = useRef(null);
  const { t } = useTranslation();
  const workspaceState = useSelector((state: any) => state.workspaces.instances.find(instance => instance.isActive));
  const [saveSelection, setSaveSelection] = useState(null);

  const pasteCleanupSettings: PasteCleanupSettingsModel  = {
    deniedAttrs: ['contenteditable', 'disabled', 'readonly', 'id', 'style', 'class']
  };

  useEffect(() => {

    if (!saveSelection || !workspaceState.data.uuid || !workspaceState.data.link.name) return;

    saveSelection.restore();
    const instance = instanceEdit.current;
    instance.executeCommand('insertHTML', `<a href="${workspaceState.data.uuid}">${workspaceState.data.link.name}<a/>`);
    instance.formatter.saveData();
    instance.formatter.enableUndo(instance);
    setSaveSelection(null);

  }, [workspaceState]);

  useEffect(() => {
    const Editable = ['create', 'edit'].includes(viewType);
    setDisableLinkClass(Editable, renderData);
  }, [viewType]);

  useEffect(() => {
    document.querySelectorAll('.e-rte-image').forEach((element) => {
      element.classList.remove('e-rte-image');
    });

    const Editable = ['create', 'edit'].includes(viewType);
    setDisableLinkClass(Editable, renderData);
  }, [viewType, value]);


  const actionComplete = (e) => {
    convertMarkdown(e, instanceEdit);
  };

  const getChanges = (args) => {
    setNewValue(args.value);
  };

  const onCreate = () => {
    setPreview(instanceEdit.current, markdownConversion, field, fullPreviewMarkdown, setTextSelection, params, renderData);
    setInlineMenuEvents(renderData, setSaveSelection);
  };

  const toolbarClick = (args) => {
    if(args.item?.subCommand === 'Maximize'){
      store.dispatch(setNavigationOpen(false));
    }
    if(args.item?.subCommand === 'Minimize'){
      store.dispatch(setNavigationOpen(true));
    }
  };

  return (
    <FormInputContainerStyled hidden={+field.hidden}>
      <FormInputLabelContainerStyled>
        {t(field.label)}
      </FormInputLabelContainerStyled>
      <ValidationLabelStyled id={`validation-label-${field.attribute.key}`}></ValidationLabelStyled>
      <div style={{ width: '100%' }}>
        <RichTextEditorComponent
          id={`markdown-preview-${field.attribute.key}`}
          ref={instanceEdit}
          formatter={field.editorMode === 'markdown' ? new MarkdownFormatter({ listTags: { OL: '1., 2., 3.' } }) : null}
          height={field.height ?? 450}
          toolbarSettings={{ enable: true, ...getToolbarSetting(field, renderData.menu?.inline?.elements) }}
          editorMode={field.type === 'markdown' ? 'Markdown' : 'HTML'}
          enableResize={true}
          showTooltip={false}
          value={value || ''}
          change={getChanges}
          created={onCreate}
          actionComplete={field.type === 'markdown' ? actionComplete : null}
          pasteCleanupSettings={pasteCleanupSettings}
          toolbarClick={toolbarClick}
        >
          <Inject services={[Toolbar, HtmlEditor, MarkdownEditor, Image, Link, Table, QuickToolbar, Resize, PasteCleanup]} />
        </RichTextEditorComponent>
      </div>
    </FormInputContainerStyled>
  );
};

RichTextField.propTypes = {
  field: PropTypes.object,
  setNewValue: PropTypes.func,
  value: PropTypes.any,
  isLocked: PropTypes.bool,
  hiddenClass: PropTypes.any,
  params: PropTypes.object,
  viewType: PropTypes.string,
  renderData: PropTypes.any
};

export default connect()(RichTextField);
