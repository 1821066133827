import { log, toast } from '../../utils/notification';
import { setBreadcrumbItems } from '../../store/workspaces/breadcrumb/actions';
import { copyURL } from './func/function.copyUrl';
import { fileDownload, base64Download } from './func/file/function.download';
import { fileView } from './func/file/function.view';
import { historyBack } from './func/function.historyBack';
import { logout } from './func/function.logout';
import { decrypt } from './func/tools/decrypt';
import { encrypt } from './func/tools/encrypt';
import { translationReload } from './func/function.translationReload';
import { addNodesGraph } from './func/function.addNodes';
import sendMail from './func/function.sendMail';
import { copyPath } from './func/function.copyPath';
import { closeDialog } from './func/function.closeDialog';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const callFunctions = async (functions: any, componentParams: any) => {

  functions.forEach((_function) => {
    try {
      log(
        `Function (alias: ${_function.function.name})`,
        {
          params: _function.params
        },
        'Function'
      );

      switch (_function.function.name) {
        case 'StoreBreadcrumb':
          return setBreadcrumbItems(_function.params);
        case 'Logout':
          return logout();
        case 'Download':
          return fileDownload(_function.params);
        case 'DownloadBase64':
          return base64Download(_function.params);
        case 'View':
          return fileView(_function.params);
        case 'Back':
        case 'Back 0':
        case 'Back 1':
          return historyBack(_function.params);
        case 'CopyURL':
          return copyURL();
        case 'Copy':
          return copyPath(_function.params);
        case 'ReloadTranslations':
          return translationReload(_function.params);
        case 'AddNodes':
          return addNodesGraph(_function.params, componentParams);
        case 'RemoveNodes':
          return componentParams ? componentParams.removeNode(_function.params) : null;
        case 'RearrangeNodes':
          return componentParams ? componentParams.reloadLayout(_function.params?.layout) : null;
        case 'Encrypt':
          return encrypt(_function.params);
        case 'Decrypt':
          return decrypt(_function.params);
        case 'SendMail':
          return sendMail(_function.params);
        case 'CloseDialog':
          return closeDialog();
        default:
          return null;
      }
    } catch (error) {
      toast.error('call _function', error.message);
      log(
        `Function (alias: ${_function.function.name})`,
        {
          params: _function.params,
          error: error.message
        },
        'Error'
      );
    }
  });
};